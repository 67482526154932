import wordpress from '../service/wordpress'

export const strict = false

export const state = () => ({
  sponsors: [],
  events: []
})

export const getters = {
  getSponsors(state) {
    return state.sponsors
  },
  getEvents(state) {
    return state.events
  }
}

export const mutations = {
  SET_SPONSORS(state, sponsors) {
    state.sponsors = sponsors
  },
  SET_EVENTS(state, events) {
    state.events = events
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { redirect, app, req }) {
    const [sponsors, events] = await Promise.all([
      wordpress.get(`sponsors?per_page=30&orderby=rand`),
      wordpress.get(`events?per_page=500`)
    ])
    commit('SET_SPONSORS', sponsors.data)
    commit('SET_EVENTS', events.data)
  }
}
