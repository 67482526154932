import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b59d1fd = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _39ab5168 = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _89b50706 = () => interopDefault(import('../pages/photo-video.vue' /* webpackChunkName: "pages/photo-video" */))
const _1cdbb8c9 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _4b57caf4 = () => interopDefault(import('../pages/about-dex.vue' /* webpackChunkName: "pages/about-dex" */))
const _baf44536 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _56e7cbf9 = () => interopDefault(import('../pages/sponsor-worden.vue' /* webpackChunkName: "pages/sponsor-worden" */))
const _73702c0c = () => interopDefault(import('../pages/sponsors.vue' /* webpackChunkName: "pages/sponsors" */))
const _c697961c = () => interopDefault(import('../pages/friends.vue' /* webpackChunkName: "pages/friends" */))
const _1b251781 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _97a78a22 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _4b59d1fd,
    name: "contact___nl"
  }, {
    path: "/events",
    component: _39ab5168,
    name: "events___nl"
  }, {
    path: "/foto-video",
    component: _89b50706,
    name: "photo-video___nl"
  }, {
    path: "/nieuws",
    component: _1cdbb8c9,
    name: "news___nl"
  }, {
    path: "/over-dex",
    component: _4b57caf4,
    name: "about-dex___nl"
  }, {
    path: "/privacy-policy",
    component: _baf44536,
    name: "privacy___nl"
  }, {
    path: "/sponsor-worden",
    component: _56e7cbf9,
    name: "sponsor-worden___nl"
  }, {
    path: "/sponsoren",
    component: _73702c0c,
    name: "sponsors___nl"
  }, {
    path: "/vrienden-van-dex",
    component: _c697961c,
    name: "friends___nl"
  }, {
    path: "/nieuws/:slug",
    component: _1b251781,
    name: "news-slug___nl"
  }, {
    path: "/",
    component: _97a78a22,
    name: "index___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
