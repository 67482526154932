import localee2e93302 from '../../assets/locales/nl-NL.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"nl","messages":{"nl":{"404":{"header":"Pagina niet gevonden","text":"Oeps, er is iets fout gegaan. De pagina die je hebt opgevraagd bestaat niet of geeft een foutmelding. Ga terug naar onze <a href='/'>homepage</a>."},"menu":{"home":"Home","about_dex":"Team Dex","news":"Nieuws","photo_video":"Foto/video","events":"Events","sponsors":"Sponsoren","friends":"Vrienden van Dex","contact":"Contact","privacy":"Privacy Policy","cookie":"Cookies"},"slider":{"prev":"prev","next":"next"},"form":{"email":"E-mailadres","company_name":"Bedrijfsnaam","name":"Naam","phone":"Telefoonnummer","message":"Bericht","send":"Verstuur","submit":"Aanmelden","opt":"Door op aanmelden te klikken geef ik Dex toestemming om mijn emailadres te gebruiken om mailings te sturen over Dex producten, recepten en meer. Ik begrijp dat ik elk moment kan uitschrijven.","thank_you":"Bedankt voor je bericht, wij nemen deze zo spoedig mogelijk in behandeling.","fail":"Er is iets fout gegaan bij het versturen, probeer het nog eens.","false_email":"Dit e-mailadres is niet geldig."},"components":{"contact":{"header":"Wil je meer weten?","button":"Neem contact op"},"news":{"read_more":"Lees verder","overview":"Naar overzicht"}},"endpoints":{"homepage":"homepage","about_dex":"over-dex","photo_video":"homepage","sponsors":"sponsoren","friends":"vrienden-van-dex","become_sponsor":"sponsor-worden","events":"events","sponsor_items":"sponsors","event_items":"events","friend_items":"friends","photo_video_items":"media","contact":"contact","news":"category/nieuws","privacy":"privacy-policy","contact_form":"https://cms.dexvandenbroek.nl/wp-json/contact-form-7/v1/contact-forms/6/feedback"},"cookies":{"buttonText":"Toestaan","linkText":"Lees meer","message":"Met behulp van cookies kunnen we analyses maken en uw ervaring op onze website verbeteren. Bij verder gebruik van onze website gaat u hiermee akkoord."}}}},
  vueI18nLoader: false,
  locales: [{"code":"nl","name":"NL","file":"nl-NL.json"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/home/dex/dex.rosegaar.work/assets/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"index":{"nl":"/"},"about-dex":{"nl":"/over-dex"},"contact":{"nl":"/contact"},"events":{"nl":"/events"},"friends":{"nl":"/vrienden-van-dex"},"photo-video":{"nl":"/foto-video"},"privacy":{"nl":"/privacy-policy"},"sponsors":{"nl":"/sponsoren"},"news/index":{"nl":"/nieuws"},"news/_slug":{"nl":"/nieuws/:slug"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"nl","name":"NL","file":"nl-NL.json"}],
  localeCodes: ["nl"],
}

export const localeMessages = {
  'nl-NL.json': () => Promise.resolve(localee2e93302),
}
